import * as actionType from "../constant/domainList";

const initialState = {
  DOMAINLISTStart: false,
  DOMAINLISTSuccess: false,
  DOMAINLISTFailed: false,
  DOMAINLISTError: "",
  DOMAINLISTData: {},
  DOMAINLISTEnd: false,
  IsDataSubmitedSuccessfully: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.DOMAINLIST_START:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isShowLoader:true,        
        DOMAINLISTSuccess:false,
        showToaster:false
      };
    case actionType.DOMAINLIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        DOMAINLISTSuccess: true,
        DOMAINListData: action.payload,
        isShowLoader:false,
        showToaster:false
      };
   
    case actionType.DOMAINLIST_FAIL:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false,
        
        DOMAINLISTSuccess:false,
        showToaster:false
      };
    case actionType.DOMAINLIST_ERROR:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        IsError: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false,
        DOMAINLISTSuccess:false,
        showToaster:false
      };
    case actionType.DOMAINLIST_END:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isShowLoader:false,
        
        DOMAINLISTSuccess:false,
        showToaster:false
      };
      case actionType.DOMAINLIST_ERRORMESSAGE:
        return {
          ...state,
          ...action.payload,
          toasterType: action.toasterType ,
          toasterMessage: action.toasterMessage,
          showToaster:true
        };

    default:
      return state;
  }
};
