const initialState = {
    dataText: '',
    ogrenciSoyad: '',
    display: [],
    video: [],
    videoTop: [],
    displayTop: [],
    displaycampName:'',
    videocampName:'',
  };
  
  export const alldataReducers = function(state = initialState, action) {
    switch (action.type) {
    
      case 'OGRENCI.DEGISTIR_SOYAD':
        const soyad = Object.assign({}, state, { ogrenciSoyad: action.payload.ogrenciSoyad });
        return soyad;
     
        case 'DATaANEW.EKLE':
           
            const liste = Object.assign({}, state, { data: action.payload.data });
            return liste;
            case 'DATANEW.EKLE':
             
                const yeniListe = action.payload.data;
              
         
              
                const aliste = Object.assign({}, state, { display: yeniListe.display,video: yeniListe.video,videoTop: yeniListe.videosumReport,displayTop: yeniListe.displaysumReport,displaycampName:yeniListe.displaysumReport[0].name ,videocampName:yeniListe.videosumReport[0].name  });
               
                return aliste;
      default:
        return state;
    }
  };