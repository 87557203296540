import { combineReducers } from 'redux';
import { alldataReducers } from './reducers/alldataReducers';
import { userReducers } from './reducers/userReducers';
import  dashboardReducer  from './reducers/dashboardReducer';
import  blackListReducer  from './reducers/blackListReducer';
import  domainListReducer  from './reducers/domainListReducer';
import  {notificationReducers}  from './reducers/notificationReducer';

const allReducers = {
    alldataReducers,
    userReducers,
    dashboard:dashboardReducer,
    blackList:blackListReducer,
    domainList:domainListReducer,
    notificationReducers
};

export const rootReducer = combineReducers(allReducers);