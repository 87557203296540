const initialState = {
    userData:[],
    user:false,
    userCreativeData:[],
    brand_list:[]
  };
  
  export const userReducers = function(state = initialState, action) {
    
    switch (action.type) {
      
      case 'User.UserData':
           // const userDataNew = action.payload.data;
         //   console.log("******",userDataNew);
        const UserData = Object.assign({}, state, { user: action.payload.data.user,userData:action.payload.data.userData });
        return UserData;
        case 'User.UserCreativeData':
          const userCreativeDataNew =[];
          userCreativeDataNew.brand= action.payload.brand_name;
          userCreativeDataNew.agency= action.payload.agency_name;
         
      const userCreativeData = Object.assign({}, state, { userCreativeData : userCreativeDataNew });
      return userCreativeData;
      case 'User.BrandList':
        // const userDataNew = action.payload.data;
      //   console.log("******",userDataNew);
     const BrandList = Object.assign({}, state, { brand_list: action.payload.data.brand_list});
     return BrandList;
        
      default:
        return state;
    }
  };