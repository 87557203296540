import * as actionType from "../constant/dashboard";

const initialState = {
  DASHBOARDStart: false,
  DASHBOARDTABLESTART: false,
  DASHBOARDSuccess: false,
  DASHBOARDTABLESuccess: false,
  DASHBOARDFailed: false,
  DASHBOARDError: "",
  DASHBOARDData: {},
  DASHBOARDTABLEData: {},
  DASHBOARDEnd: false,
  IsDataSubmitedSuccessfully: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.DASHBOARD_START:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isShowLoader:true,        
        DASHBOARDSuccess:false
      };
      case actionType.DASHBOARD_TABLE_START:
        return {
          ...state,
          ...action.payload,
          isAuthenticated: true,
          isShowLoader:true,        
          DASHBOARDTABLESuccess:false
        };
    case actionType.DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        DASHBOARDSuccess: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false
      };
       case actionType.DASHBOARD_TABLE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        DASHBOARDTABLESuccess: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false
      };
      case actionType.DASHBOARDDROPDOWN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isDashboardDropDownSuccess:true,
          isAuthenticated: true,
         // DASHBOARDSuccess: true,
         // IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        };
    
    case actionType.DASHBOARD_FAIL:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false,
        
        DASHBOARDSuccess:false
      };
    case actionType.DASHBOARD_ERROR:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        IsError: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false,
        DASHBOARDSuccess:false
      };
    case actionType.DASHBOARD_END:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isShowLoader:false,
        
        DASHBOARDSuccess:false
      };
      case actionType.DASHBOARD_AUTH:
        return {
          ...state,
          ...action.payload,
          isAuthenticated: false,
          isShowLoader:false,
          
          DASHBOARDSuccess:false
        };
    default:
      return state;
  }
};
