


export const DASHBOARD_START = 'DASHBOARD_START';
export const DASHBOARD_TABLE_START = 'DASHBOARD_TABLE_START';
export const DASHBOARD_END = 'DASHBOARD_END';
export const DASHBOARD_AUTH = 'DASHBOARD_AUTH';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_TABLE_SUCCESS = 'DASHBOARD_TABLE_SUCCESS';
export const DASHBOARD_FAIL = 'DASHBOARD_FAIL';
export const DASHBOARDDROPDOWN_SUCCESS ='DASHBOARDDROPDOWN_SUCCESS';

