const initialState = {
    notificationFlag: false,
    notificationList: [],
    notficationCount: 0,
    notificationUpdataData:false
  };
  
  export const notificationReducers = function(state = initialState, action) {
    
    switch (action.type) {
      
      case 'notificationUpdataData':
       
        const notificationData = Object.assign({}, state,  action.payload);
        return notificationData;
       
      default:
        return state;
    }
  };