import React, { Component,Suspense } from "react";
import logo from "./logo.svg";

import "./App.css";
import "./assets/custom-scss/_front-end-v2.css"

import { connect } from "react-redux";

import { BrowserRouter as Router, Switch, Route, matchPath, Redirect } from "react-router-dom";
import NewLoader from "./Components/NewLoader";

const PrivateRoute = React.lazy(() => import("./Components/PrivateRoute"));
const Header = React.lazy(() => import('./Components/Header'));
const Login = React.lazy(() => import('./Components/Login'));

const Register = React.lazy(() => import('./Components/Register/Register'));

const renderLoader = () =><NewLoader/>;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
   //console.log("==",props.auth);
    if (!props.auth) {

      const formData  = new FormData();
    
       formData.append("checkAuth", true);
      fetch(process.env.REACT_APP_API_Val2 +"checkAuth", {
        method: "POST",
        body: formData,
        credentials: 'include'
       
      })
        .then(response => response.json())
        .then(data => {
          
          if (data.user) {
            
            this.props.userLoad(data);

            this.setState({loading:true});
            const ele = document.getElementById('ipl-progress-indicator')
          
            if(ele){
              // fade out
              ele.classList.add('available')
              setTimeout(() => {
                // remove from DOM
                ele.outerHTML = ''
              }, 2000)
            }
           
          }else {
           
            const ele = document.getElementById('ipl-progress-indicator')
            if(ele){
              // fade out
              ele.classList.add('available')
              setTimeout(() => {
                // remove from DOM
                ele.outerHTML = ''
              }, 2000)
            }
            this.setState({loading:true});
            
          }
          // Prints result from `response.json()` in getRequest
        }).catch(error=>{
          const ele = document.getElementById('ipl-progress-indicator')
          if(ele){
            // fade out
            ele.classList.add('available')
            setTimeout(() => {
              // remove from DOM
              ele.outerHTML = ''
            }, 2000)
          }
          this.setState({loading:true});
        });
    }
 
  
  }
  
  
 
  render(props) {
   

    return (
       
      <div className="App" >
        {this.state.loading?
        (<Suspense fallback={renderLoader()}>
        <Router >
      
        
          
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route  exact path="/register/:id/:token/" component={Register} />
            <PrivateRoute   loggedIn={this.props.auth} path="/*" component={Header}  />
        
           
            </Switch>
          
         
      
        </Router>
        </Suspense>)
        :""}
      </div>

     
    );
  }
}

const mapStateToProps = (state, ownProps) => {
 // console.log("**--******",state.userReducers.user);
  return {
    auth: state.userReducers.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    userLoad: (data) => {
    
      dispatch({ type: 'User.UserData', payload: {data : data} });
    },
   
  };
};
const AppDataConnected = connect(mapStateToProps,mapDispatchToProps)(App);

export default AppDataConnected;
