import * as actionType from "../constant/blackList";

const initialState = {
  BLACKLISTStart: false,
  BLACKLISTSuccess: false,
  BLACKLISTFailed: false,
  BLACKLISTError: "",
  BLACKLISTData: {},
  BLACKLISTEnd: false,
  IsDataSubmitedSuccessfully: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.BLACKLIST_START:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isShowLoader:true,        
        BLACKLISTSuccess:false,
        showToaster:false
      };
    case actionType.BLACKLIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        BLACKLISTSuccess: true,
        blackListData: action.payload,
        isShowLoader:false,
        showToaster:false
      };
      case actionType.BLACKLISTDROPDOWN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isBLACKLISTDropDownSuccess:true,
          BrandListDD: action.payload,
          showToaster:false
        //  isAuthenticated: true,
         // BLACKLISTSuccess: true,
         // IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        };
    
    case actionType.BLACKLIST_FAIL:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false,
        
        BLACKLISTSuccess:false,
        showToaster:false
      };
    case actionType.BLACKLIST_ERROR:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        IsError: true,
        IsDataSubmitedSuccessfully: action.IsDataSubmitedSuccessfully,
        isShowLoader:false,
        BLACKLISTSuccess:false,
        showToaster:false
      };
    case actionType.BLACKLIST_END:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isShowLoader:false,
        
        BLACKLISTSuccess:false,
        showToaster:false
      };
      case actionType.BLACKLIST_ERRORMESSAGE:
        return {
          ...state,
          ...action.payload,
          toasterType: action.toasterType ,
          toasterMessage: action.toasterMessage,
          showToaster:true
        };

    default:
      return state;
  }
};
