import React from "react";
import "./index.css";
const NewLoader = () => {
  return (
    <div class="ipl-progress-indicator" id="ipl-progress-indicator">
      <div class="ipl-progress-indicator-head">
        <div class="first-indicator"></div>
        <div class="second-indicator"></div>
      </div>
      <div class="insp-logo-frame">
        <img src="200x200.png" />
      </div>
    </div>
  );
};

export default NewLoader;
